import { SvgLogoProps } from "./types";

export function getSvgLogo({
  name,
  width,
  height,
  className,
}: SvgLogoProps): string {
  const logos = {
    "mkm-blue":
      `<svg class="${className}"` +
      `${width ? ` width="${width}"` : " "}` +
      `${height ? ` height="${height}"` : " "}` +
      ' viewBox="0 0 408 120" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M106.796 0L74.7598 47.9778L42.7238 0H0V119.972H42.7238V71.9945L74.7598 119.972L106.796 71.9945V119.972H149.492V0H106.796Z" fill="#143981" /><path d="M272.621 0H224.252L208.303 23.9058L192.216 48.0332V0.0277008H149.492V120H192.216V71.9668L208.303 96.0942L224.252 120H272.621L232.446 60L272.621 0Z" fill="white" /><path d="M365.304 0L333.268 47.9778L301.232 0H272.621L258.508 21.0803V98.9197L272.594 119.972H301.232V71.9945L333.268 119.972L365.304 71.9945V119.972H408V0H365.304Z" fill="#143981" /></svg>',
    "mkm-yellow":
      `<svg class="${className}"` +
      `${width ? ` width="${width}"` : " "}` +
      `${height ? ` height="${height}"` : " "}` +
      ' viewBox="0 0 408 120" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M106.796 0L74.7598 47.9778L42.7238 0H0V119.972H42.7238V71.9945L74.7598 119.972L106.796 71.9945V119.972H149.492V0H106.796Z" fill="#FFCC00" /><path d="M272.621 0H224.252L208.303 23.9058L192.216 48.0332V0.0277008H149.492V120H192.216V71.9668L208.303 96.0942L224.252 120H272.621L232.446 60L272.621 0Z" fill="white" /><path d="M365.304 0L333.268 47.9778L301.232 0H272.621L258.508 21.0803V98.9197L272.594 119.972H301.232V71.9945L333.268 119.972L365.304 71.9945V119.972H408V0H365.304Z" fill="#FFCC00" /></svg>',
    "mkm-grey":
      `<svg class="${className}"` +
      `${width ? ` width="${width}"` : " "}` +
      `${height ? ` height="${height}"` : " "}` +
      ' viewBox="0 0 408 120" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M106.796 0L74.7598 47.9778L42.7238 0H0V119.972H42.7238V71.9945L74.7598 119.972L106.796 71.9945V119.972H149.492V0H106.796Z" fill="#DADADA" /><path d="M272.621 0H224.252L208.303 23.9058L192.216 48.0332V0.0277008H149.492V120H192.216V71.9668L208.303 96.0942L224.252 120H272.621L232.446 60L272.621 0Z" fill="#F8F8F8" /><path d="M365.304 0L333.268 47.9778L301.232 0H272.621L258.508 21.0803V98.9197L272.594 119.972H301.232V71.9945L333.268 119.972L365.304 71.9945V119.972H408V0H365.304Z" fill="#DADADA" /></svg>',
    "mkm-home":
      `<svg class="${className}"` +
      `${width ? ` width="${width}"` : " "}` +
      `${height ? ` height="${height}"` : " "}` +
      `viewBox="0 0 409 194" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M188.286 172.154C188.286 160.103 180.077 151.047 168.001 151.047C155.867 151.047 147.717 160.103 147.717 172.154C147.717 184.206 155.933 193.261 168.001 193.261C180.069 193.261 188.286 184.206 188.286 172.154ZM152.982 172.147C152.982 162.609 158.804 155.571 168.001 155.571C177.133 155.571 183.02 162.602 183.02 172.147C183.02 181.626 177.199 188.723 168.001 188.723C158.804 188.723 152.982 181.685 152.982 172.147ZM104.066 173.806V192.523H109.155V151.72H104.066V169.275H80.1642V151.72H75.0748V192.523H80.1642V173.806H104.066ZM261.851 192.523V158.575L247.937 192.523H245.85L232.003 158.575V192.523H226.914V151.72H234.207L246.897 182.796L259.647 151.72H266.94V192.523H261.851ZM307.157 151.72V192.516H333.944V187.992H312.246V173.923H333.519V169.399H312.246V156.244H333.944V151.72H307.157Z" fill="#72787A"/><path d="M107.305 0.509277L75.2691 48.3217L43.2331 0.509277H0.509277V120.068H43.2331V72.2555L75.2691 120.068L107.305 72.2555V120.068H150.001V0.509277H107.305Z" fill="#FFCC00"/><path d="M273.13 0.509277H224.761L208.812 24.3327L192.725 48.3769V0.536883H150.001V120.095H192.725V72.2279L208.812 96.2721L224.761 120.095H273.13L232.955 60.3024L273.13 0.509277Z" fill="#72787A"/><path d="M365.813 0.509277L333.777 48.3217L301.741 0.509277H273.13L259.017 21.5169V99.0878L273.103 120.068H301.741V72.2555L333.777 120.068L365.813 72.2555V120.068H408.509V0.509277H365.813Z" fill="#FFCC00"/></svg>`,
  };

  return logos[name] || `<div></div>`;
}
