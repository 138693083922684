<template>
  <div
    data-testid="logocomponent"
    :class="[
      'ui-flex ui-justify-center ui-items-center',
      className && className.length > 0 ? className : 'icon-renderer',
    ]"
    v-html="
      getSvgLogo({
        name: name,
        width: width,
        height: height,
        className: className,
      })
    "
  />
</template>

<script lang="ts" setup>
import type { SvgLogoProps } from "./types";
import { computed } from "vue";
import { getSvgLogo } from "./getSvgLogo";
import { toRefs } from "vue";

const props = defineProps<SvgLogoProps>();
const { name, width, height, className } = toRefs(props);

const computedWidth = computed(() =>
  width.value ? `${width.value}px` : "auto",
);

const computedHeight = computed(() =>
  height.value ? `${height.value}px` : "auto",
);
</script>

<style scoped>
.icon-renderer {
  width: v-bind(computedWidth);
  height: v-bind(computedHeight);
}
</style>
